import React, { useEffect, useState } from "react";

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        const windowScrollToTop = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        }
        window.addEventListener("scroll", windowScrollToTop);
        return window.removeEventListener("scroll", windowScrollToTop);
    }, []);

    return (
        <>
            {showButton && (
                <div
                    className="omg-scroll-to-top"
                    onClick={() => scrollToTop()}
                >
                    <a className="omg-scroll-to-top-button">
                        <svg
                            width={48}
                            height={48}
                            viewBox="0 0 47 47"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="23.4704"
                                cy="23.4704"
                                r="23.4704"
                                fill="#0E65EC"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.7633 28.7953C12.1622 28.1942 12.1622 27.2196 12.7633 26.6185L21.9986 17.3832C22.5997 16.7821 23.5743 16.7821 24.1754 17.3832L33.4107 26.6185C34.0118 27.2196 34.0118 28.1942 33.4107 28.7953C32.8096 29.3964 31.8351 29.3964 31.234 28.7953L23.087 20.6483L14.9401 28.7953C14.339 29.3964 13.3644 29.3964 12.7633 28.7953Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                </div>
            )}
        </>
    );
};

export default ScrollToTop;
