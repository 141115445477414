import { CON_SHEET, CON_SHEET_SUCCESSS, CON_SHEET_FAIL } from "../types";
import moment from "moment";

export const contactState = {
  isConnect: false,
  feedbackDocument: {},
  currentHeader: [],
  hasNextHeader: false,
  currentSheet: {},
  currentMonth: moment().format("MMM YYYY"),
  hasNextMonth: true,
  loading: false,
  err: false,
};

export const contactReducer = (state, { type, payload }) => {
  switch (type) {
    case CON_SHEET:
      return {
        ...state,
        loading: true,
      };
    case CON_SHEET_SUCCESSS:
      const { header, doc, hasNext, currSheet } = payload;
      return {
        ...state,
        loading: false,
        isConnect: true,
        feedbackDocument: doc,
        currentHeader: header,
        hasNextMonth: hasNext,
        currentSheet: currSheet,
      };
    case CON_SHEET_FAIL:
      return {
        ...state,
        loading: false,
        err: true,
      };

    default:
      return state;
  }
};
