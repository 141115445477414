import React, { useState } from "react";
import { courierContext } from "../contexts";

const CourierProvider = ({ children }) => {
    const [couriers, setCouriers] = useState(null);
    const [totalCourier, setTotalCourier] = useState(null);
    const [loadingCourier, setLoadingCourier] = useState(false);
    const [first, setFirst] = useState(true);
    const [page, setPage] = useState(1);

    return (
        <courierContext.Provider value={{ couriers, setCouriers, totalCourier, setTotalCourier, loadingCourier, setLoadingCourier, first, setFirst, page, setPage }}>{children}</courierContext.Provider>
    );
};

export default CourierProvider;
