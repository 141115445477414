import { SET_MODAL } from "./constants";

export const initState = {
    modalOpen: false,
}


function reducer(state, action) {
    switch (action.type) {
        case SET_MODAL:
            return {
                ...state,
                modalOpen: action.payload
            }
        default:
            return state
    }
}

export default reducer;