export default {
  openGraph: {
    type: "website",
    locale: "en",
    url: "https://www.ipblocker.io/",
    site_name: "Blockify",
    images: [
      {
        url: "https://www.ipblocker.io/images/homepage/thumbnail_blockify.png",
        width: 1200,
        height: 627,
        alt: "Blockify image thumbnail",
        type: "image/png",
      },
    ],
  },
};

export const defaultKeyword = [
  {
    name: "keywords",
    content:
      "Tracking number, tracking info, payment hold, dispute, chargeback, sync tracking, paypal tracking, paypal, stripe, transaction id",
  },
  {
    name: "description",
    content:
      "Effortlessly block visitors by location/IP, reduce fraud, analyze traffic, and get useful insights. Say goodbye to scammers, choose Blockify with peace of mind!",
  },
  {
    name: "author",
    content: "Blockify ‑ Fraud IP Blocker",
  },
];

export const defaultLinkTags = [
  {
    rel: "icon",
    href: "/images/favicon.ico",
  },
];
